import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { RootState } from '../../store/configureStore'
import { FullTranslation } from './Translate.types'

export enum FetchingState {
	Idle = 'idle',
	Loading = 'loading',
	Failed = 'failed',
}

export interface TranslationState {
	translation: FullTranslation[]
  status: FetchingState.Idle | FetchingState.Loading | FetchingState.Failed
}

const initialState: TranslationState = {
	translation: [],
  status: FetchingState.Idle,
}

export const fetchTranslationBySearchTerm = createAsyncThunk(
	'translation/fetchTranslation',
	async ({ word }: { word: string }) => {
		const response = await fetch(`${process.env.REACT_APP_API_URL}/word/${word}`)
		const data = await response.json()
		return data
	}
)

export const fetchTranslationByWordId = createAsyncThunk(
	'translation/fetchTranslationByWordId',
	async ({ wordId }: { wordId: string }) => {
		const response = await fetch(`${process.env.REACT_APP_API_URL}/word/${wordId}`)
		const data = await response.json()
		return [data]
	}
)

export const fetchRandomTranslation = createAsyncThunk(
	'translation/fetchRandomTranslation',
	async () => {
		const response = await fetch(`${process.env.REACT_APP_API_URL}/word/random`)
		const data = await response.json()
		return [data]
	}
)

export const translationSlice = createSlice({
  name: 'translation',
  initialState,
  reducers: { },
  extraReducers: (builder) => {
    builder
			.addCase(fetchTranslationBySearchTerm.pending, (state) => {
				state.status = FetchingState.Loading
			})
			.addCase(fetchTranslationBySearchTerm.fulfilled, (state, action) => {
				state.status = FetchingState.Idle
				state.translation = action.payload
			})
			.addCase(fetchTranslationBySearchTerm.rejected, (state) => {
				state.status = FetchingState.Failed
			})
			.addCase(fetchTranslationByWordId.pending, (state) => {
				state.status = FetchingState.Loading
			})
			.addCase(fetchTranslationByWordId.fulfilled, (state, action) => {
				state.status = FetchingState.Idle
				state.translation = action.payload
			})
			.addCase(fetchTranslationByWordId.rejected, (state) => {
				state.status = FetchingState.Failed
			})
			.addCase(fetchRandomTranslation.pending, (state) => {
				state.status = FetchingState.Loading
			})
			.addCase(fetchRandomTranslation.fulfilled, (state, action) => {
				state.status = FetchingState.Idle
				state.translation = action.payload
			})
			.addCase(fetchRandomTranslation.rejected, (state) => {
				state.status = FetchingState.Failed
			})
  },
})

// actions


// selectors
export const selectTranslation = (state: RootState) => state.translation.translation
export const selectLoadingStatus = (state: RootState) => state.translation.status
export const selectFetchingState = (state: RootState) => state.translation.status
export default translationSlice.reducer