import ReactGA from 'react-ga4';

export default class Analytics {

  static isInitialized = false

  static initGA() {
    ReactGA.initialize([
      {
        trackingId: 'G-LE3NEJWN16',
      },
    ])
    Analytics.isInitialized = true
  }

  static logEvent(category = '', action = '', label?: string, userInteraction?: true) {
    if (Analytics.isInitialized) {
      ReactGA.event({
        category: category,
        action: action,
        label: label,
        nonInteraction: !userInteraction,
      })
    }
  }

}