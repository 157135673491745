import React, { Suspense, useEffect, useState } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import s from './App.module.scss'
import CookieConsentModal from './components/CookieConsent/CookieConsent'
import Loading from './components/Loading/Loading'
import useCookies from './hooks/useCookies'
import Analytics from './Utils/analytics'

const Start = React.lazy(() => import('./views/Start/Start'))
const Translate = React.lazy(() => import('./views/Translate/Translate'))
//const Dictionary = React.lazy(() => import('./views/Dictionary/Dictionary'))
const Quiz = React.lazy(() => import('./views/Quiz/Quiz'))
const CookiePolicy = React.lazy(() => import('./views/CookiePolicy/CookiePolicy'))

// Error pages
const NotFound = React.lazy(() => import('./views/NotFound/NotFound'))

const router = createBrowserRouter([
  {
    path: '/',
    element: <Start />,
    errorElement: <NotFound />
  },
  {
    path: '/quiz',
    element: <Quiz />,
    errorElement: <NotFound />,
  },
  {
    path: '/translate/:word',
    element: <Translate />,
    errorElement: <NotFound />,
  },
  /*
  {
    path: '/letter/:letter',
    element: <Dictionary />,
    errorElement: <NotFound />,
  },
  */
  {
    path: '/cookie-policy',
    element: <CookiePolicy />,
    errorElement: <NotFound />,
  },
])

const App = (): JSX.Element => {

  const cookies = useCookies()
  const [hasConsent, setHasConsent] = useState(false)
  
  useEffect(() => {
    const consent = cookies.getCookie('cookieConsent')
    if (consent === 'true') {
      setHasConsent(true)
    }
  }, [cookies])

  useEffect(() => {
    if (hasConsent) {
      Analytics.initGA()
    }
  }, [hasConsent])

  return (
    <div className={s.App}>
      <Suspense fallback={
        <Loading />
      }>
      <RouterProvider router={router}></RouterProvider>
      </Suspense>
      { !window.location.href.includes('cookie-policy') && (
        <CookieConsentModal onConsentGiven={setHasConsent}/>
      )}
    </div>
  )
}

export default App
