import sigillHeader from '../../assets/images/sigill-header.svg'
import s from './Loading.module.scss'


const Loading = (): JSX.Element => {
  return (
    <div className={s.Loading} data-testid="loading">
      <div>
        <img src={sigillHeader} alt="spinner" />
      </div>
    </div>
  )
}

export default Loading