import React, { Dispatch, SetStateAction } from 'react'

import useCookies from '../../hooks/useCookies'
import Modal from '../Modal/Modal'
import s from './CookieConsent.module.scss'

interface CookieConsentModalProps {
  onConsentGiven: Dispatch<SetStateAction<boolean>>
}

const CookieConsentModal = ({
  onConsentGiven,
}: CookieConsentModalProps): JSX.Element => {
  
  const cookies = useCookies()
  const consentCookie = cookies.getCookie('cookieConsent')

  const setConsentCookie = (value: boolean) => {
    cookies.setCookieValue('cookieConsent', value.toString(), 150)
    onConsentGiven(value)
  }

  const cookieConsentCookieIsSet = () => {
    return consentCookie === 'true' || consentCookie === 'false'
  }

  return (
    <>
    <Modal 
          show={!cookieConsentCookieIsSet()} 
          showCloseBtn={false}
          title={'Cookie information'}
          customStyles={{ 'background': '#fff' }}>
      <div className={s.Wrapper}>
        <div className={s.CookieConsentText}>
          Den här sidan använder cookies för att förbättra din upplevelse.<br />
          Vissa av dessa är funktionella och kan därmed inte stängas av. <br />
          Andra är av analytisk betydelse och hjälper oss att förstå hur du använder sidan. <br/>
          Dessa kan du stänga av genom att klicka på "Neka". <br />
          Genom att klicka på "Acceptera" godkänner du att vi använder cookies.<br />
          Vill du veta mer om hur vi hanterar cookies, läs <a href="/cookie-policy">vår policy.</a>
        </div>
        <div className={s.CookieButtonWrapper}>
          <button className={`${s.CookieBtn} ${s.DenyBtn}`} 
                  onClick={() => setConsentCookie(false)}>
                    Neka
          </button>
          <button className={`${s.CookieBtn} ${s.AcceptBtn}`} 
                  onClick={() => setConsentCookie(true)}>
                    Acceptera
          </button>
        </div>
      </div>
    </Modal>
    </>
  ) 
}

export default CookieConsentModal