import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { RootState } from "../../store/configureStore"
import { FetchingState } from "../Translate/Translate.reducers"
import { Word } from "../Translate/Translate.types"

export interface DictionaryState {
	dictionary: Word[]
	hasMore: boolean
  status: FetchingState.Idle | FetchingState.Loading | FetchingState.Failed
}

const initialState: DictionaryState = {
  dictionary: [],
  hasMore: true,
  status: FetchingState.Idle,
}

export const fetchWordsByLetter = createAsyncThunk(
	'dictionary/fetchWordsByLetter',
	async ({ letter, page }: { letter: string, page: number }) => {
		const response = await fetch(`${process.env.REACT_APP_API_URL}/word/letter/${letter}?page=${page}`)
		const data = await response.json()
		return data
	}
)

export const dictionarySlice = createSlice({
  name: 'dictionary',
  initialState,
  reducers: { },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWordsByLetter.pending, (state) => {
        state.status = FetchingState.Loading
      })
      .addCase(fetchWordsByLetter.fulfilled, (state, action) => {
        state.status = FetchingState.Idle
        state.dictionary = [...state.dictionary, ...action.payload]
        state.hasMore = action.payload.length === 50
      })
      .addCase(fetchWordsByLetter.rejected, (state) => {
        state.status = FetchingState.Failed
      })
  },
})

// selectors
export const selectDictionary = (state: RootState) => state.dictionary.dictionary
export const hasMoreDictionaryWords = (state: RootState) => state.dictionary.hasMore
export const selectDictionaryLoading = (state: RootState) => state.dictionary.status

export default dictionarySlice.reducer
