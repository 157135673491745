import React, { ReactNode } from 'react'

import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import s from './Modal.module.scss'

interface ModalProps {
  show: boolean
  title?: string
  children: ReactNode
  showCloseBtn?: boolean
  onClose?: () => void
  customStyles?: React.CSSProperties
}

const Modal = ({ show, title, children, onClose, customStyles, showCloseBtn }: ModalProps): JSX.Element => {

  return (
    <>
      { show && (
        <>
        <div className={s.Modal}>
          <div className={s.ModalContent} style={customStyles}>
            <div className={s.ModalHeader}>
              <h2 className={s.ModalTitle}>{title}</h2>
              { showCloseBtn && (
                <button className={s.CloseModalBtn} onClick={onClose}>
                  <FontAwesomeIcon icon={faXmark}/>
                </button>
              )}
            </div>
            <div className={s.ModalBody}>
              {children}
            </div>
          </div>
        </div>
        <div className={s.Backdrop} ></div>
        </>
      )}
    </>
  )
}

export default Modal
