import { initReactI18next } from "react-i18next"

import i18n from "i18next"
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector"
import Backend from 'i18next-http-backend'

export const availableLanguages = ['se', 'z']

i18n
  .use(Backend)
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'se',
    fallbackLng: 'se',
    debug: false,
    interpolation: {
      escapeValue: false
    }
  })

  export default i18n