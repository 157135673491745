import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { RootState } from "../../store/configureStore"
import { FetchingState } from "../Translate/Translate.reducers"
import { QuizAnswer, QuizDifficulty, QuizQuestion } from "./Quiz.types"

export interface QuizState {
	questions: QuizQuestion[]
	status: FetchingState.Idle | FetchingState.Loading | FetchingState.Failed
	score: QuizAnswer[] | null
}

const initialState: QuizState = {
  questions: [],
  status: FetchingState.Idle,
  score: null,
}


export const fetchQuizQuestions = createAsyncThunk(
	'translation/fetchQuizQuestions',
	async ({ level }: { level: QuizDifficulty }) => {
		const response = await fetch(`${process.env.REACT_APP_API_URL}/quiz?level=${level}`)
		const data = await response.json()
		return data
	}
)

export const correctQuiz = createAsyncThunk(
	'translation/correctQuiz',
	async (quiz: QuizState) => {

		let body = []
		for (const question of quiz.questions) {
			body.push({
				id: question.id,
				word: question.word,
				userGuess: question.answer,
			})
		}

		const response = await fetch(`${process.env.REACT_APP_API_URL}/quiz/score`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		})

		const data = await response.json()
		return data
	}
)

export const quizSlice = createSlice({
  name: 'quiz',
  initialState,
  reducers: {
    setQuestionAnswered: (state, action) => {
			const questionIndex = state.questions.findIndex((item: QuizQuestion) => item.id === action.payload.questionId)
			state.questions[questionIndex].answered = true
			state.questions[questionIndex].answer = action.payload.answer
		},
  },
  extraReducers: (builder) => {
    builder
			.addCase(fetchQuizQuestions.pending, (state) => {
				state.status = FetchingState.Loading
			})
			.addCase(fetchQuizQuestions.fulfilled, (state, action) => {
				state.status = FetchingState.Idle
				let questions = action.payload as QuizQuestion[]
				questions.forEach((question: QuizQuestion) => {
					question.answered = false
					question.answer = ''
				})
				state.questions = questions
			})
			.addCase(fetchQuizQuestions.rejected, (state) => {
				state.status = FetchingState.Failed
			})
			.addCase(correctQuiz.pending, (state) => {
				state.status = FetchingState.Loading
			})
			.addCase(correctQuiz.fulfilled, (state, action) => {
				state.status = FetchingState.Idle
				state.score = action.payload
			})
			.addCase(correctQuiz.rejected, (state) => {
				state.status = FetchingState.Failed
			})
  },
})

// actions
export const { setQuestionAnswered } = quizSlice.actions

// selectors
export const selectQuiz = (state: RootState) => state.quiz
export const selectQuizLoading = (state: RootState) => state.quiz.status
export const selectQuizScore = (state: RootState) => state.quiz.score

export default quizSlice.reducer