import { createSlice } from "@reduxjs/toolkit"

import { RootState } from "../../store/configureStore"

export interface SearchState {
  searchTerm: string
}

const initialState: SearchState = {
  searchTerm: '',
}

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchTerm: (state, action) => ({
      ...state,
      searchTerm: action.payload
    }),
    setSearch: (state, action) => ({
      ...state,
      searchTerm: action.payload.searchTerm,
    })
  },
})

export const { 
  setSearchTerm,
  setSearch
} = searchSlice.actions
export const selectSearchTerm = (state: RootState) => state.search.searchTerm
export const selectSearch = (state: RootState) => state.search
export default searchSlice.reducer