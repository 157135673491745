import { Action, combineReducers, configureStore, PreloadedState, ThunkAction } from '@reduxjs/toolkit'

import searchReducer from '../components/Search/Search.reducers'
import dictionaryReducer from '../views/Dictionary/Dictionary.reducers'
import quizReducer from '../views/Quiz/Quiz.reducers'
import translationReducer from '../views/Translate/Translate.reducers'

const rootReducer = combineReducers({
  dictionary: dictionaryReducer,
  translation: translationReducer,
  quiz: quizReducer,
  search: searchReducer,
})

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState
  })
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>